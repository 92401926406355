import { Container, Row, Col, Card, Modal, Spinner, Accordion } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import TarsService from '../services/TarsService';
import dayjs from 'dayjs';
import MaskedInput from "react-text-mask";
import { createNumberMask } from "text-mask-addons";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import QRCode from "react-qr-code";

const defaultMaskOptions = {
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2, // how many digits allowed after the decimal
    //   integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
}

const currencyMask = createNumberMask(defaultMaskOptions)

const WompiButton = ({ data }) => {

    const formRef = useRef(null)

    useEffect(() => {
        const script = document.createElement('script');

        if (script.readyState) {  // only required for IE <9
            script.onreadystatechange = function () {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;

                    const iframes = document.getElementsByClassName("waybox-iframe")
                    for (let element of iframes) {
                        element.setAttribute("scrolling", "yes");
                    };

                }
            };
        } else {  //Others
            script.onload = function () {
                const iframes = document.getElementsByClassName("waybox-iframe")
                for (let element of iframes) {
                    element.setAttribute("scrolling", "yes");
                };
            };
        }

        script.src = "https://checkout.wompi.co/widget.js";
        script.setAttribute("data-render", "button")
        script.setAttribute("data-public-key", process.env.REACT_APP_WOMPI_PUBLIC_KEY)
        script.setAttribute("data-currency", "COP")
        script.setAttribute("data-amount-in-cents", data.AmountInCents)
        script.setAttribute("data-reference", data.PaymentReference)
        script.setAttribute("data-signature:integrity", data.EncryptedString)
        script.setAttribute("data-redirect-url", process.env.REACT_APP_URL_PAYMENT_REDIRECT)

        formRef.current.appendChild(script);
    }, []);

    return <form ref={formRef} >
    </form>
}

const Payment = () => {
    const INTENTOS = 11;
    const TIEMPO_ESPERA = 60;

    const { GetReferenceInfo, CustomPaymentAmount, GetBankList, GetPSEUrl, CreateNequiPayment, GetNequiPayment, CancelNequiPayment } = TarsService();

    const ReferenciaRef = useRef(null)
    const navigate = useNavigate();
    const [referenceInfo, setReferenceInfo] = useState(null);
    const [reference, setReference] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [referenceError, setReferenceError] = useState(false);
    const [cont, setCont] = useState(0);
    const [contTimeout, setContTimeout] = useState(TIEMPO_ESPERA);
    const [contMessage, setContMessage] = useState(false);
    const [show, setShow] = useState(false);

    const [selectedReference, setSelectedReference] = useState({});
    const [selectedMinimumPayment, setSelectedMinimumPayment] = useState({});
    const [paymentType, setPaymentType] = useState(null);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [showEfectyModal, setShowEfectyModal] = useState(false);
    const [showPSEModal, setShowPSEModal] = useState(false);
    const [showNequiModal, setShowNequiModal] = useState(false);

    const [amountError, setAmountError] = useState(false);
    const [showAdvancePaymentModal, setShowAdvancePaymentModal] = useState(false);
    const [showOverPaymentModal, setShowOverPaymentModalModal] = useState(false);
    const [showPendingPaymentModal, setShowPendingPaymentModal] = useState(false);

    const [showPlanModal, setShowPlanModal] = useState(false);
    const [opOption, setOpOption] = useState(0);
    const [apOption, setApOption] = useState(0);
    let moneyLocale = Intl.NumberFormat('es-CO');
    const MySwal = withReactContent(Swal);
    const [selectValue, setSelectValue] = useState("");
    const [bank, setBank] = useState("0");
    const [bankList, setBankList] = useState([]);

    const [FPayment, setFPayment] = useState(0);

    const [intervalNequi, setIntervalNequi] = useState(0);
    const [QRvalue, setQRvalue] = useState(null);
    const [showAnotherNumberForm, setShowAnotherNumberForm] = useState(false);
    const [anotherNumber, setAnotherNumber] = useState("");
    const [anotherNumberError, setAnotherNumberError] = useState("");
    const [pendingUPPayment, setPendingUPPayment] = useState(false);
    const [transactionIdNequi, setTransactionIdNequi] = useState();

    useEffect(() => {
        document.title = "Pagos";

        window.scrollTo(0, 0);

        ReferenciaRef.current.focus();

        const queryParameters = new URLSearchParams(window.location.search)
        const tempReference = queryParameters.get("reference")

        if (tempReference) {
            setReference(tempReference);
            searchReference(tempReference);
        }

    }, []);

    useEffect(() => {
        ReferenciaRef.current.focus();
    }, [referenceInfo]);

    useEffect(() => () => {
        clearInterval(intervalNequi);

    }, []);


    const goToContact = () => {
        navigate("/contact");
        window.scrollTo(0, 0)
    }

    const searchReference = (internalReference) => {

        GetBankList().then(res => {
            var data = JSON.parse(res.data?.data ?? null);
            setBankList(data ?? [])
        });


        setErrorMessage(null);
        setReferenceError(false);

        if (!internalReference) {
            setReferenceError(true);
            return;
        }

        let tempCont = cont + 1
        setCont(tempCont);

        if (tempCont >= INTENTOS) {
            setReferenceError(false);
            setReference("");
            setErrorMessage(null);
            setContMessage(true);

            let intervalId;
            intervalId = setInterval(() => {


                setContTimeout(t => {

                    const modifiedValue = t - 1;

                    if (modifiedValue <= 0) {
                        clearInterval(intervalId);
                        setContMessage(false);
                        setCont(0);
                        return TIEMPO_ESPERA;
                    }
                    return modifiedValue;

                });


            }, 1000);

            return;


        }

        setLoading(true);
        GetReferenceInfo(internalReference).then(res => {
            setLoading(false);
            if (res.status === 200) {

                var data = JSON.parse(res.data.data);
                setReferenceInfo(data)
            }
            else {



                setReference("");
                setErrorMessage(res.data.message);
                setReferenceError(true);
                ReferenciaRef.current.focus();
            }

        });



    }


    const handleOpenModal = (r) => {

        setShow(true)
        setSelectedReference(r)

        let tempMIP = r.Payments.find(p => p.IdConcept === "MIP");
        setSelectedMinimumPayment(tempMIP)
        if (tempMIP?.Enabled) {
            setPaymentType(r.Payments.find(s => s.IdConcept === "MIP"))
            setSelectValue("MIP")
        }

    }

    // useEffect(() => {

    //     if (selectedReference && selectedReference.Payments) {
    //         let tempMIP = selectedReference.Payments.find(p => p.LabelPayment === "Pago Mínimo");
    //         console.log(tempMIP)
    //         if (tempMIP?.Enabled) { handleChangePaymentType("MIP") }


    //     }

    // }, [selectedReference]);

    const handleClose = () => {
        setPaymentType(null)
        setShow(false);
    }

    const handleTransferModalClose = () => {
        setShowTransferModal(false);
    }

    const handleEfectyModalClose = () => {
        setShowEfectyModal(false);
    }

    const handlePSEModalClose = () => {
        setShowPSEModal(false);
    }

    const handleNequiModalClose = () => {
        // setQRvalue(null);
        setShowAnotherNumberForm(false);
        setAnotherNumber("")
        // clearInterval(intervalNequi);
        setShowNequiModal(false);
        // setPendingUPPayment(false)
    }

    const handleAdvancePaymentModalClose = () => {
        setApOption(0)
        setShowAdvancePaymentModal(false);
    }

    const handleOverPaymentModalClose = () => {
        setOpOption(0)
        setShowOverPaymentModalModal(false);
    }

    const handlePendingPaymentModalClose = () => {

        setShowPendingPaymentModal(false);
    }

    const handlePlanModalClose = () => {
        setShowPlanModal(false);
    }


    const handleChangeBank = (value) => {
        setBank(value);
    }

    const handleChangePaymentType = (type) => {
        setSelectValue(type)
        setPaymentType(null)
        setAmountError(false)

        if (type === "MAN") {
            setPaymentType({ LabelAmount: "", IdConcept: "MAN" })
        }
        else if (type) {

            setPaymentType(selectedReference.Payments.find(s => s.IdConcept === type))
        }
        else {
            setPaymentType(null)
        }

    }

    const handleAnotherNumberNequi = () => {
        setShowAnotherNumberForm(true);
    }

    const openNequiModal = () => {
        setShowNequiModal(true)
    }


    const getStatusNequi = () => {

        setLoading(true)
        GetNequiPayment(transactionIdNequi).then(resG => {
            setLoading(false)
            let dataG = JSON.parse(resG.data.data);

            if (resG.status === 200) {

                if (dataG && dataG.status && dataG.status !== "PENDING") {

                    navigate("/paymentResultNequi?transactionId=" + transactionIdNequi)
                }
                else {

                    Swal.fire({
                        title: "El pago se encuentra pendiente",
                        html: "Completa el pago con tu aplicación de Nequi",
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: "#00a9eb",
                        icon: 'info'
                    })
                }
            }
            else {
                handleNequiModalClose();
                Swal.fire({
                    html: resG.data.message,
                    showCancelButton: true,
                    cancelButtonText: "Contactanos",
                    cancelButtonColor: "#ff9e25",
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#00a9eb",
                    icon: 'error'
                }).then((result) => {
                    if (!result.isConfirmed) {
                        window.open("https://www.meddipay.com.co/contact", "_blank", "noreferrer")
                    }
                });
            }


        });
    }

    const CreateNequiPaymentI = (type) => {

        setLoading(true);
        CreateNequiPayment(anotherNumber, selectedReference.Reference, paymentType.PaymentReferenceNEQUI, paymentType.AmountInCents / 100, type).then(res => {
            setLoading(false);
            if (res.status === 200) {
                setShowAnotherNumberForm(false)
                let data = JSON.parse(res.data.data);
                setTransactionIdNequi(data.transactionId)
                if (type === "QR") {
                    setQRvalue("bancadigital-" + data.transactionId)
                }
                else {
                    setPendingUPPayment(true)
                }

                let inequi = setInterval(() => {


                    GetNequiPayment(data.transactionId).then(resG => {
                        let dataG = JSON.parse(resG.data.data);

                        if (dataG && dataG.status && dataG.status !== "PENDING") {
                            clearInterval(inequi);

                            navigate("/paymentResultNequi?transactionId=" + data.transactionId)
                        }

                    });


                }, 30 * 1000);


                setIntervalNequi(inequi);

            }
            else {

                handleNequiModalClose();
                Swal.fire({
                    html: res.data.message,
                    showCancelButton: true,
                    cancelButtonText: "Contactanos",
                    cancelButtonColor: "#ff9e25",
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#00a9eb",
                    icon: 'error'
                }).then((result) => {
                    if (!result.isConfirmed) {
                        window.open("https://www.meddipay.com.co/contact", "_blank", "noreferrer")
                    }
                });
            }
        })
    }


    const newProcessNequi = () => {
        Swal.fire({
            html: "¿Desea cancelar el proceso actual de pago y generar uno nuevo?",
            showCancelButton: true,
            cancelButtonText: "NO",
            cancelButtonColor: "gray",//"#ff9e25",
            confirmButtonText: 'SI',
            confirmButtonColor: "#00a9eb",
            icon: 'info'
        }).then((result) => {
            if (result.isConfirmed) {
                clearInterval(intervalNequi);
                setLoading(true)
                GetNequiPayment(transactionIdNequi).then(resG => {
        
                    let dataG = JSON.parse(resG.data.data);
        
                    if (resG.status === 200) {
        
                        if (dataG && dataG.status && dataG.status !== "PENDING") {
                            setLoading(false);
                            navigate("/paymentResultNequi?transactionId=" + transactionIdNequi)
                        }
                        else {
        
        
                            CancelNequiPayment(anotherNumber ?? null, selectedReference.Reference, paymentType.PaymentReferenceNEQUI, transactionIdNequi).then(resCancel => {
                        
                                setLoading(false);              
                                setQRvalue(null);
                                setShowAnotherNumberForm(false);
                                setAnotherNumber("")
                                
                                setShowNequiModal(false);
                                setPendingUPPayment(false);
                                setShow(false);
                                setPaymentType(null);
                                setSelectValue("")
                                searchReference(reference);
                            })
        
                           
                        }
                    }
                    else {
                        handleNequiModalClose();
                        Swal.fire({
                            html: resG.data.message,
                            showCancelButton: true,
                            cancelButtonText: "Contactanos",
                            cancelButtonColor: "#ff9e25",
                            confirmButtonText: 'Aceptar',
                            confirmButtonColor: "#00a9eb",
                            icon: 'error'
                        }).then((result) => {
                            if (!result.isConfirmed) {
                                window.open("https://www.meddipay.com.co/contact", "_blank", "noreferrer")
                            }
                        });
                    }
        
        
                });
        
            }
        });
    }

    const GetUrlPSE = () => {

        if (!bank || bank === "0") {

            Swal.fire({
                html: "Seleccione el banco",
                showDenyButton: false,
                confirmButtonText: 'ACEPTAR',
                confirmButtonColor: "#00a9eb",
                icon: 'error'
            });

            return;

        }

        setLoading(true);
        GetPSEUrl("SKAL", bank, selectedReference.Reference, paymentType.PaymentReferencePSE, paymentType.AmountInCents / 100, paymentType.LabelPayment).then(res => {

            if (res.status === 200) {
                var data = JSON.parse(res.data.data);
                window.location.replace(data.pseURL);
            }
            else {
                setLoading(false);
                Swal.fire({
                    html: res.data.message,
                    showCancelButton: true,
                    cancelButtonText: "Contactanos",
                    cancelButtonColor: "#ff9e25",
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#00a9eb",
                    icon: 'error'
                }).then((result) => {
                    if (!result.isConfirmed) {
                        window.open("https://www.meddipay.com.co/contact", "_blank", "noreferrer")
                    }
                });

            }

        })
    }


    const validateManualPayment = () => {

        let amount = Number(paymentType.LabelAmount.replaceAll("$", "").replaceAll(".", ""));

        let totalPayment = selectedReference.Payments.find(j => j.IdConcept === "TOP")?.AmountInCents / 100;

        if (!amount) {
            setAmountError(true)
        } else if (amount < 5000) {
            setAmountError(true)
            Swal.fire({
                html: "El valor debe ser mayor a $5.000",
                showDenyButton: false,
                confirmButtonText: 'CONTINUAR',
                confirmButtonColor: "#00a9eb",
                icon: 'warning'
            });
        }
        else if (amount > 2000000000) {
            setAmountError(true)
            Swal.fire({
                html: "El valor debe ser menor a $2.000.000.000",
                showDenyButton: false,
                confirmButtonText: 'CONTINUAR',
                confirmButtonColor: "#00a9eb",
                icon: 'warning'
            });
        }
        else if (amount > totalPayment) {
            setAmountError(true)
            Swal.fire({
                html: "El valor debe ser menor al valor total adeudado",
                showDenyButton: false,
                confirmButtonText: 'CONTINUAR',
                confirmButtonColor: "#00a9eb",
                icon: 'warning'
            });
        }
        else {
            setAmountError(false)

            let minimumPayment = selectedMinimumPayment.AmountInCents / 100;

            if (minimumPayment === 0) {

                setShowAdvancePaymentModal(true);
            }
            else if (amount > minimumPayment) {

                if (selectedReference.IsBilled && !selectedReference.IsInDefault) {
                    setShowPendingPaymentModal(true);
                }
                else {
                    setShowOverPaymentModalModal(true)
                }


            }
            else if (amount < minimumPayment) {

                if (selectedReference.IsBilled && !selectedReference.IsInDefault) {
                    setShowPendingPaymentModal(true);
                }
                else {
                    getCustomPaymentAmount(amount, null);
                }
            }
            else {

                getCustomPaymentAmount(amount, null);
            }
        }
    }

    const handlePendingPayment = (type) => {
        let amount = Number(paymentType.LabelAmount.replaceAll("$", "").replaceAll(".", ""));
        let minimumPayment = selectedMinimumPayment.AmountInCents / 100;

        if (type === 1) {
            setFPayment(1)
            if (amount < minimumPayment) {
                getCustomPaymentAmount(amount, null);

            } else {
                setShowOverPaymentModalModal(true)
            }
        }
        else {


            Swal.fire({
                title: "Vas a entrar en mora...",
                html: `...si no pagas tu cuota de <b>${selectedMinimumPayment?.LabelAmount}</b> antes del <b>${selectedMinimumPayment.LabelLimitDate}</b>. Te recomendamos pagar tu cuota antes de abonar a capital`,
                showDenyButton: true,
                denyButtonText: "Abonar todo a capital",
                denyButtonColor: "grey",
                confirmButtonText: 'Pagar cuota<br/>',

                confirmButtonColor: "#79d853",
                iconColor: "red",
                icon: 'info'
            }).then((result) => {

                if (result.isDenied) {
                    setFPayment(2);
                    setShowOverPaymentModalModal(true)
                }

                if (result.isConfirmed) {
                    setFPayment(1)
                    if (amount < minimumPayment) {
                        getCustomPaymentAmount(amount, null);

                    } else {
                        setShowOverPaymentModalModal(true)
                    }
                }

            });


        }


        setShowPendingPaymentModal(false)
    }

    const getCustomPaymentAmount = (amount, option) => {
        let body = {
            reference: selectedReference.Reference,
            amount: amount,
            paymentOption: option
        };
        setLoading(true)
        CustomPaymentAmount(body).then(res => {
            setLoading(false)

            if (res.status === 200) {

                setShowOverPaymentModalModal(false)
                setShowAdvancePaymentModal(false)

                let data = JSON.parse(res.data.data)


                if (!data.hasError) {

                    if (data.hasWarning) {
                        Swal.fire({
                            html: data.warningMessage,
                            showDenyButton: true,
                            denyButtonText: 'REGRESAR',
                            confirmButtonText: 'CONTINUAR',
                            confirmButtonColor: "#00a9eb",
                            denyButtonColor: "gray",
                            icon: 'warning'
                        }).then((result) => {
                            setShowPendingPaymentModal(false)
                            if (result.isConfirmed) {
                                let temp = data.CustomerReferences[0].Payments[0];
                                setSelectedReference({ ...selectedReference, Payments: [...selectedReference.Payments, temp] })
                                setPaymentType(temp)
                            } else if (result.isDenied) {

                            }
                        })
                    } else {
                        let temp = data.CustomerReferences[0].Payments[0];
                        setSelectedReference({ ...selectedReference, Payments: [...selectedReference.Payments, temp] })
                        setPaymentType(temp)
                    }

                }
                else {
                    Swal.fire({
                        html: data.errorMessage,
                        showDenyButton: false,
                        confirmButtonText: 'CONTINUAR',
                        confirmButtonColor: "#00a9eb",
                        icon: 'error'
                    });
                    setShowOverPaymentModalModal(false)
                    setShowAdvancePaymentModal(false)
                    setShowPendingPaymentModal(false)
                    setPaymentType({ LabelAmount: "", IdConcept: "MAN" })
                }
            }
            else {
                Swal.fire({
                    html: "Se generó un error",
                    showDenyButton: false,
                    confirmButtonText: 'CONTINUAR',
                    confirmButtonColor: "#00a9eb",
                    icon: 'warning'
                });
            }

        })
    }


    const submitWompi = (id) => {

        let pDiv = document.getElementById(id).parentElement.querySelector('.waybox-button');;

        pDiv.click();
    }


    const overPaymentRequest = () => {

        if (opOption === 0) {
            Swal.fire({
                html: "Seleccione una opción",
                showDenyButton: false,
                confirmButtonText: 'CONTINUAR',
                confirmButtonColor: "#00a9eb",
                icon: 'warning'
            });

            return;
        }
        let amount = Number(paymentType.LabelAmount.replaceAll("$", "").replaceAll(".", ""));

        if (FPayment === 0 || FPayment === 1) {
            getCustomPaymentAmount(amount, opOption === 1 ? "NEI" : opOption === 2 ? "RET" : "REI");
        }
        else {
            getCustomPaymentAmount(amount, opOption === 1 ? "NEI" : opOption === 2 ? "NIRET" : "NIREI");
        }


        setOpOption(0)
        setShowPendingPaymentModal(false)
    }


    const advancePaymentRequest = () => {

        if (apOption === 0) {
            Swal.fire({
                html: "Seleccione una opción",
                showDenyButton: false,
                confirmButtonText: 'CONTINUAR',
                confirmButtonColor: "#00a9eb",
                icon: 'warning'
            });

            return;
        }
        let amount = Number(paymentType.LabelAmount.replaceAll("$", "").replaceAll(".", ""));
        getCustomPaymentAmount(amount, apOption === 1 ? "NEI" : apOption === 2 ? "RET" : "REI");
        setApOption(0)
    }

    const handleOpOption = (op) => {
        setOpOption(op);
    }

    const handleApOption = (op) => {
        setApOption(op);
    }

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
        // console.log('totally custom!')
        { },
        );

        return (
            <button
                type="button"
                style={{
                    backgroundColor: "white",
                    width: "100%",
                    border: "solid",
                    borderRadius: "10px",

                    color: "black",
                    display: "inline-flex",
                    fontSize: "14px",
                    borderColor: "gray"
                }}
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    }

    function CustomToggleOtherOPS({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
        // console.log('totally custom!')
        { },
        );

        return (
            <button
                type="button"
                style={{
                    backgroundColor: "white",
                    width: "100%",
                    border: "none",

                    color: "black",
                    display: "inline-flex",
                    fontSize: "14px",

                }}
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    }


    return (<>



        <Container style={{ marginTop: "20px", marginBottom: "30px" }}>

            <Row style={{ display: referenceInfo ? "none" : "initial" }}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
                    <h1 style={{ fontWeight: "bold" }}>Paga tu cuota</h1>
                    <p>
                        A continuación podrás pagar la cuota de tus créditos introduciendo la referencia de pago. La referencia de pago la podrás encontrar en las comunicaciones que te enviamos periódicamente, como correos electronicos, mensajes de texto SMS o whatsapps.
                    </p>
                    <p style={{ fontWeight: "bold" }}>
                        La referencia de pago NO es tu cédula
                    </p>

                    <input type="text" value={reference} onChange={event => { setReference(event.target.value); setReferenceError(false); setErrorMessage(null); }}
                        disabled={cont >= INTENTOS}
                        ref={ReferenciaRef} placeholder="Referencia de pago" style={{ margin: "0 auto" }}
                        className={"gap-2 mb-4 p-2 border-2 rounded-pill mt-4 form-control reference-input " + (referenceError ? "is-invalid" : "")} />

                    {errorMessage && <> <br /> <p style={{ color: "red" }}>{errorMessage}</p> </>}
                    {contMessage && <> <br /> <p style={{ color: "black" }}>{`Has realizado demasiadas consultas, por favor espere ${contTimeout} segundos para realizar una nueva consulta`}</p> </>}

                    <br />
                    <button disabled={loading || cont > INTENTOS} className="mp-big-button bg-mp-green" style={{ border: "none" }} onClick={() => searchReference(reference)}>Consultar</button>
                    <br />
                    <br />
                    <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_URL_CHAN}>¿No encuentras tu referencia de pago?</a>

                </Col>

            </Row>

            <Row style={{ display: referenceInfo ? "initial" : "none" }}>

                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
                    <h1 style={{ fontWeight: "bold" }}>Paga tu cuota</h1>
                    <br />
                    {referenceInfo && referenceInfo.CustomerReferences && referenceInfo.CustomerReferences.map(r => {
                        if (r.SearchedReference) {
                            let p = r.Payments.find(p => p.IdConcept === "MIP");
                            return <Card key={r.Reference} className="reference-card" style={{ margin: "0 auto", marginBottom: "20px", borderRadius: "14px", padding: "20px 15px", boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.1)" }}>


                                <Container>
                                    <Row >
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <span style={{ fontWeight: "bold", fontSize: "20px" }}>Crédito <span style={{ color: "#00a9eb" }}>#{r.Reference}</span></span>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                            Pago Mínimo:
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right", color: p.Textcolor, fontWeight: p.BoldText ? "bold" : "unset" }}>
                                            {p.LabelAmount}
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                            Fecha Límite:
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right", color: p.Textcolor, fontWeight: p.BoldText ? "bold" : "unset" }}>
                                            {p.LabelLimitDate ?? "-"}
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
                                            <br />
                                            {p.AmountInCents > 0 ?
                                                // <WompiButton data={{
                                                //     AmountInCents: p.AmountInCents,
                                                //     PaymentReference: p.PaymentReference,
                                                //     EncryptedString: p.EncryptedString,
                                                // }} />
                                                <button className="mp-big-button bg-mp-primary" style={{ border: "none" }} onClick={() => handleOpenModal(r)} >Pagar ahora</button>
                                                : <button className="mp-big-button bg-mp-green" style={{ border: "none" }} onClick={() => handleOpenModal(r)} >Abonar</button>
                                            }

                                        </Col>
                                    </Row>
                                </Container>
                            </Card>
                        }
                        return null
                    })}
                </Col>

                {referenceInfo && referenceInfo.CustomerReferences && referenceInfo.CustomerReferences.length > 1 &&
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", fontSize: "20px", marginBottom: "20px" }}>
                        Tus otros Créditos
                    </Col>}

                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>

                    {referenceInfo && referenceInfo.CustomerReferences && referenceInfo.CustomerReferences.length > 1 && referenceInfo.CustomerReferences.map(r => {
                        if (!r.SearchedReference) {
                            let p = r.Payments.find(p => p.IdConcept === "MIP");
                            return <Card key={r.Reference} className="reference-card" style={{ margin: "0 auto", marginBottom: "20px", borderRadius: "14px", padding: "20px 15px", boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.1)" }}>


                                <Container>
                                    <Row >
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <span style={{ fontWeight: "bold", fontSize: "20px" }}>Crédito <span style={{ color: "#00a9eb" }}>#{r.Reference}</span></span>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                            Pago Mínimo:
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right", color: p.Textcolor, fontWeight: p.BoldText ? "bold" : "unset" }}>
                                            {p.LabelAmount}
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                            Fecha Límite:
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right", color: p.Textcolor, fontWeight: p.BoldText ? "bold" : "unset" }}>
                                            {p.LabelLimitDate ?? "-"}
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
                                            <br />
                                            {p.AmountInCents > 0 ?
                                                // <WompiButton data={{
                                                //     AmountInCents: p.AmountInCents,
                                                //     PaymentReference: p.PaymentReference,
                                                //     EncryptedString: p.EncryptedString,
                                                // }} />
                                                <button className="mp-big-button bg-mp-primary" style={{ border: "none" }} onClick={() => handleOpenModal(r)} >Pagar ahora</button>
                                                : <button className="mp-big-button bg-mp-green" style={{ border: "none" }} onClick={() => handleOpenModal(r)} >Abonar</button>
                                            }

                                        </Col>
                                    </Row>
                                </Container>
                            </Card>
                        }
                        return null
                    })}
                </Col>


                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
                    <br />
                    <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={() => {
                        setReferenceInfo(null);
                        setReference("");
                    }}>Regresar</button>

                </Col>

            </Row>

            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>

                    <br />
                    <br />
                    <p>Si necesitas asistencia adicional o tienes alguna consulta, estaremos encantados de atenderte.</p>
                    <button className="mp-big-button bg-mp-orange" style={{ border: "none" }} onClick={goToContact}>¡Contáctanos!</button>
                    <br />
                    <br />
                </Col>
            </Row>
        </Container>


        {loading &&
            (
                <div className="my-loader">
                    <div className="my-loader-container">
                        <img src="assets/images/img/MeddipayLoaderEllipsis.gif" alt="loader"></img>

                    </div>
                </div>
            )}


        <Modal show={show} onHide={handleClose} dialogClassName="modal-payment">
            <Modal.Header closeButton>
                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "20px" }}>Crédito <span style={{ color: "#00a9eb" }}>#{selectedReference.Reference}</span>
                    </span>
                </div>
            </Modal.Header>

            <Modal.Body>
                {selectedReference && <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold" }}>
                            Pago mínimo
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", color: selectedMinimumPayment.Textcolor, fontWeight: selectedMinimumPayment.BoldText ? "bold" : "unset" }}>
                            {selectedMinimumPayment.LabelAmount}
                            <br />
                            <br />

                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold" }}>
                            Fecha límite de pago
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", color: selectedMinimumPayment.Textcolor, fontWeight: selectedMinimumPayment.BoldText ? "bold" : "unset" }}>
                            {selectedMinimumPayment.LabelLimitDate ?? "-"}
                            <br />
                            <br />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold" }}>
                            <span style={{ fontSize: "14px", color: "#00a9eb", cursor: "pointer" }} onClick={() => setShowPlanModal(true)}><i className="bi bi-table"> </i>Ver plan de pagos completo</span>
                            <br />
                            <br />
                        </Col>

                    </Row>


                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold" }}>
                            ¿Cuánto vas a pagar?

                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", marginTop: "10px" }}>

                            <select className="form-select" style={{ textAlign: "center", borderWidth: "2px" }} value={selectValue} onChange={(event) => handleChangePaymentType(event.target.value)}>
                                <option defaultValue={null}></option>

                                {selectedReference.Payments?.map(c => {
                                    if (c.IdConcept !== "MAV")
                                        return <option disabled={!c.Enabled} key={c.IdConcept} value={c.IdConcept}>{c.LabelPayment}</option>
                                })}
                                <option value="MAN">Introducir valor manual</option>
                            </select>
                        </Col>

                    </Row>

                    <Row>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
                            {paymentType &&
                                <>
                                    <div className="input-group">
                                        <MaskedInput placeholder="Introducir valor" disabled={paymentType.IdConcept !== "MAN"} style={{ marginTop: "20px", textAlign: "center", borderWidth: "2px" }} mask={currencyMask} name="valor-pagar" value={paymentType.LabelAmount} onChange={(event) => { setAmountError(false); setPaymentType({ ...paymentType, LabelAmount: event.target.value }) }} className={`form-control ${amountError && "is-invalid"}`} autoComplete="off">

                                        </MaskedInput >

                                        {paymentType.PaymentReference && paymentType.IdConcept === "MAV" && <div className="input-group-append" style={{ marginTop: "20px" }}>
                                            <span className="input-group-text" style={{ borderWidth: "2px", borderRadius: "0px", cursor: "pointer" }} onClick={() => { setPaymentType({ LabelAmount: "", IdConcept: "MAN" }) }}><i className="bi bi-x-lg"></i></span>

                                        </div>}
                                    </div>



                                    {/* <input disabled={paymentType.IdConcept !== "MAN"} style={{ width: "100%" }} type="text" value={paymentType.LabelAmount} onChange={(event) => { setPaymentType({ ...paymentType, LabelAmount: event.target.value }) }} className="d-flex justify-content-center gap-2 text-center  p-2 border  border-2 rounded-pill mt-4" /> */}

                                    {/* {paymentType.PaymentReference && paymentType.IdConcept === "MAV" && <button type="submit" style={{ margin: "20px 0px 0px", color: "white", textDecoration: "none", border: "none", minWidth: "200px", borderRadius: "70px", backgroundColor: "gray", height: "54px" }} onClick={() => { setPaymentType({ LabelAmount: "", IdConcept: "MAN" }) }} >Limpiar</button>} */}
                                    {paymentType.PaymentReference && <p style={{ fontSize: "13px", margin: "20px 0px 0px", }}>Este valor se ha calculado a fecha de <b>{dayjs().format("DD/MM/YYYY")}</b> y solamente es valido para pago realizados el mismo día.</p>}

                                    {paymentType.IdConcept === "MAN" && <button type="submit" style={{ margin: "20px 0px 0px", color: "white", textDecoration: "none", border: "none", minWidth: "200px", borderRadius: "70px", backgroundColor: "#00a9eb", height: "54px" }} onClick={validateManualPayment} >Validar</button>}


                                </>

                            }


                        </Col>

                    </Row>


                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "20px" }}>
                            {paymentType && paymentType.PaymentReference && "¿Cómo vas a pagar?"}

                        </Col>

                    </Row>





                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "10px" }}>
                            {paymentType && paymentType.PaymentReference && <div style={{ cursor: "pointer", height: "70px", padding: "10px", border: "solid 2px", borderColor: "#da0c81", width: window.innerWidth > 600 ? "80%" : "100%", margin: "0 auto", borderRadius: "30px" }}
                                onClick={openNequiModal}>

                                <div style={{ float: "left", textAlign: "left", marginLeft: "20px" }}>
                                    <span style={{ fontSize: "15px" }}>Nequi</span><br />
                                    <span style={{ fontSize: "10px", fontWeight: "500" }}>Pago se aplica al instante</span>
                                </div>

                                <img src="assets/images/img/nequi.png" style={{ width: "50px", cursor: "pointer", float: "right" }} ></img>

                            </div>
                            }
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "10px" }}>
                            {paymentType && paymentType.PaymentReference && <div style={{ cursor: "pointer", height: "70px", padding: "10px", border: "solid 2px", borderColor: "#205191", width: window.innerWidth > 600 ? "80%" : "100%", margin: "0 auto", borderRadius: "30px" }}
                                onClick={() => setShowPSEModal(true)}>

                                <div style={{ float: "left", textAlign: "left", marginLeft: "20px" }}>
                                    <span style={{ fontSize: "15px" }}>PSE</span><br />
                                    <span style={{ fontSize: "10px", fontWeight: "500" }}>Pago se aplica al instante</span>
                                </div>

                                <img src="assets/images/img/BotonPSE-01.png" style={{ width: "50px", cursor: "pointer", float: "right" }} ></img>


                            </div>
                            }
                        </Col>

                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "10px" }}>
                            {paymentType && paymentType.PaymentReference &&
                                <div style={{ cursor: "pointer", height: "70px", padding: "10px", border: "solid 2px", borderColor: "#ffd919", width: window.innerWidth > 600 ? "80%" : "100%", margin: "0 auto", borderRadius: "30px" }}
                                    onClick={() => setShowEfectyModal(true)}>

                                    <div style={{ float: "left", textAlign: "left", marginLeft: "20px" }}>
                                        <span style={{ fontSize: "15px" }}>Efecty</span><br />
                                        <span style={{ fontSize: "10px", fontWeight: "500" }}>Pago se aplica en 1 día</span>
                                    </div>

                                    <img src="assets/images/img/EfectyImg.png" style={{ width: "50px", cursor: "pointer", float: "right" }} ></img>


                                </div>
                            }


                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "10px" }}>




                            {selectedReference && selectedReference.Payments && selectedReference.Payments.map(p => {
                                if (p.AmountInCents > 0 && p.Enabled) {
                                    return <div key={p.PaymentReference + "_" + p.IdConcept}
                                        style={{ position: "relative", overflow: "hidden", height: "70px", padding: "10px", border: "solid 2px", borderColor: "#b0f2ae", width: window.innerWidth > 600 ? "80%" : "100%", margin: "0 auto", borderRadius: "30px", display: p.IdConcept === paymentType?.IdConcept && p.PaymentReference === paymentType?.PaymentReference ? "" : "none" }}>


                                        <div id={p.PaymentReference + "_" + p.IdConcept} onClick={() => submitWompi(p.PaymentReference + "_" + p.IdConcept)}
                                            style={{ cursor: "pointer", width: "100%", height: "100%", position: "absolute", top: "0", left: "0" }}></div>

                                        <div style={{ float: "left", textAlign: "left", marginLeft: "20px" }}>
                                            <span style={{ fontSize: "15px" }}>Wompi <span style={{ fontSize: "10px" }}>(Daviplata, Tarjeta)</span></span><br />
                                            <span style={{ fontSize: "10px", fontWeight: "500" }}>Pago se aplica al instante</span>
                                        </div>

                                        <WompiButton data={{
                                            AmountInCents: p.AmountInCents,
                                            PaymentReference: p.PaymentReference,
                                            EncryptedString: p.EncryptedString,
                                        }} />

                                    </div>

                                }
                            })}




                        </Col>


                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "10px" }}>
                            {paymentType && paymentType.PaymentReference &&
                                <div style={{ cursor: "pointer", height: "70px", padding: "10px", border: "solid 2px", borderColor: "rgb(0 169 235)", width: window.innerWidth > 600 ? "80%" : "100%", margin: "0 auto", borderRadius: "30px" }}
                                    onClick={() => setShowTransferModal(true)}>


                                    <div style={{ float: "left", textAlign: "left", marginLeft: "20px" }}>
                                        <span style={{ fontSize: "15px" }}>Transferencia</span><br />
                                        <span style={{ fontSize: "10px", fontWeight: "500" }}>Pago se aplica en 2-3 días</span>
                                    </div>

                                    <button className="transfer-button" type="submit" style={{ float: "right" }}></button>

                                </div>
                            }
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "50px auto", width: "80%" }}>
                            <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={handleClose}>Regresar</button>
                        </Col>
                    </Row>





                </Container>}
            </Modal.Body>


        </Modal>




        <Modal show={showTransferModal} onHide={handleTransferModalClose} dialogClassName="modal-transfer">
            <Modal.Header closeButton>
                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "20px" }}>Pagar mediante transferencia
                    </span>
                </div>
            </Modal.Header>

            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", border: "solid", borderColor: "black", width: "80%" }}>
                            <b>Banco:</b> Bancolombia<br />
                            <b>Titular:</b> MEDDIPAY S.A.S<br />
                            <b>NIT:</b> 901763399-5<br />
                            <b>Tipo de Cuenta:</b> Ahorros<br />
                            <b>Numero de cuenta:</b> 85900003352<br />

                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", width: "80%" }}>
                            <p>Una vez realizada la transferencia, por favor, enviar soporte, junto con referencia del crédito a cual esta realizando el pago al siguiente correo electrónico: </p>
                            <a target="_blank" rel="noreferrer" href="mailto:hola@meddipay.com">hola@meddipay.com</a>

                        </Col>
                    </Row>


                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "50px auto", width: "80%" }}>
                            <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={handleTransferModalClose}>Regresar</button>
                        </Col>
                    </Row>



                </Container>
            </Modal.Body>


        </Modal>


        <Modal show={showPSEModal} onHide={handlePSEModalClose} dialogClassName="modal-transfer">
            <Modal.Header closeButton>
                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "20px" }}>Pagar mediante PSE
                    </span>
                </div>
            </Modal.Header>

            <Modal.Body>
                <Container>

                    <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "10px auto" }}>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>Crédito <span style={{ color: "#00a9eb" }}>#{selectedReference?.Reference}</span>
                        </span>
                    </div>

                    <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "50px auto" }}>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>Referencia de pago <span style={{ color: "#00a9eb" }}>#{paymentType?.PaymentReferencePSE}</span>
                        </span>
                    </div>

                    <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "50px auto" }}>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>Concepto <span style={{ color: "#00a9eb" }}>{paymentType?.LabelPayment}</span>
                        </span>
                    </div>

                    <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "50px auto" }}>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>Valor <span style={{ color: "#00a9eb" }}>{paymentType?.LabelAmount}</span>
                        </span>
                    </div>



                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "20px", }}>
                            Seleccione el banco

                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto" }}>

                            <select className="form-select" style={{ textAlign: "center", borderWidth: "2px" }} value={bank} onChange={(event) => handleChangeBank(event.target.value)}>
                                <option defaultValue={null}></option>

                                {bankList.map(c => {

                                    return <option key={c.financialInstitutionCode} value={c.financialInstitutionCode}>{c.financialInstitutionName}</option>
                                })}

                            </select>

                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "50px auto", width: "80%" }}>
                            <button className="mp-big-button" style={{ border: "none", backgroundColor: "rgb(0 169 235)" }} onClick={GetUrlPSE}>Continuar</button>
                        </Col>
                    </Row>


                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "0px auto", width: "80%" }}>
                            <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={handlePSEModalClose}>Regresar</button>
                        </Col>
                    </Row>



                </Container>
            </Modal.Body>


        </Modal>

        <Modal show={showNequiModal} onHide={handleNequiModalClose} dialogClassName="modal-transfer">
            <Modal.Header closeButton>
                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "20px" }}>Pagar mediante Nequi
                    </span>
                </div>
            </Modal.Header>

            <Modal.Body>
                <Container>

                    <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "10px auto" }}>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>Crédito <span style={{ color: "#00a9eb" }}>#{selectedReference?.Reference}</span>
                        </span>
                    </div>

                    <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "20px auto" }}>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>Referencia de pago <span style={{ color: "#00a9eb" }}>#{paymentType?.PaymentReferenceNEQUI}</span>
                        </span>
                    </div>

                    <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "20px auto" }}>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>Concepto <span style={{ color: "#00a9eb" }}>{paymentType?.LabelPayment}</span>
                        </span>
                    </div>

                    <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "20px auto" }}>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>Valor <span style={{ color: "#00a9eb" }}>{paymentType?.LabelAmount}</span>
                        </span>
                    </div>





                    {!QRvalue && !showAnotherNumberForm && !pendingUPPayment && <>

                        <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "50px 0px 20px 0px" }}>
                            <span style={{ fontWeight: "bold", fontSize: "20px" }}>¿Qué NEQUI vas a usar para el pago?
                            </span>
                        </div>



                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "20px auto", width: "85%" }}>
                                <button className="mp-big-button" style={{ width: "100%", border: "none", backgroundColor: "rgb(0 169 235)", height: "60px" }} onClick={() => CreateNequiPaymentI("UP")}>Recibir notificación en {selectedReference.MaskedPhone}</button>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "20px auto", width: "85%" }}>
                                <button className="mp-big-button" style={{ width: "100%", border: "none", backgroundColor: "rgb(0 169 235)", height: "60px" }} onClick={handleAnotherNumberNequi}>Recibir notificación en otro celular</button>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "20px auto", width: "85%" }}>
                                <button className="mp-big-button" style={{ width: "100%", border: "none", backgroundColor: "rgb(0 169 235)", height: "60px" }} onClick={() => CreateNequiPaymentI("QR")}>Generar código QR</button>
                            </Col>
                        </Row>


                    </>
                    }
                    {
                        pendingUPPayment && <>
                            <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "30px 0px 20px 0px" }}>
                                <span style={{ fontWeight: "bold", fontSize: "17px" }}>La notificación ha sido enviada a su celular, y luego de autenticarse en su App de Nequi, deberá aceptar el pago desde el centro de notificaciones.
                                </span>
                            </div>
                            <Row style={{ textAlign: "center" }}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Spinner animation="border" role="status" style={{ color: "#00a9eb" }} >
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner></Col>
                            </Row>
                            <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "20px auto" }}>
                                <span style={{ fontWeight: "bold", fontSize: "17px" }}>Oprime el boton de continuar cuando hayas realizado el pago
                                </span>
                            </div>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "0px auto", width: "80%" }}>
                                    <button className="mp-big-button" style={{ border: "none", backgroundColor: "rgb(0 169 235)" }} onClick={getStatusNequi}>Continuar</button>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "20px auto", width: "80%" }}>
                                    <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={newProcessNequi}>Generar nuevo proceso</button>
                                </Col>
                            </Row>
                        </>
                    }
                    {showAnotherNumberForm &&
                        <>
                            <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "30px 0px 0px 0px" }}>
                                <span style={{ fontWeight: "bold", fontSize: "17px" }}>Ingresa el número de celular
                                </span>
                            </div>
                            <Row>

                                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "0px auto", width: "80%" }}>
                                    <input type="text" value={anotherNumber} onChange={event => { setAnotherNumber(event.target.value); setAnotherNumberError(false); }}
                                        style={{ margin: "0 auto" }}
                                        className={"gap-2 mb-4 p-2 border-2 rounded-pill mt-4 form-control reference-input " + (anotherNumberError ? "is-invalid" : "")} />
                                </Col>

                            </Row>

                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "20px auto", width: "80%" }}>
                                    <button className="mp-big-button" style={{ border: "none", backgroundColor: "rgb(0 169 235)" }} onClick={() => {
                                        if (typeof Number(anotherNumber) !== 'number') { setAnotherNumberError(true); return };
                                        if (anotherNumber.length < 10) { setAnotherNumberError(true); return };
                                        CreateNequiPaymentI("UP");
                                    }}>Enviar</button>
                                </Col>
                            </Row>
                        </>



                    }

                    {QRvalue && <>
                        <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "20px auto" }}>
                            <span style={{ fontWeight: "bold", fontSize: "17px" }}>Escanea este código QR mediante tu aplicación de NEQUI para realizar el pago
                            </span>
                        </div>

                        <Row>

                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "0px auto", width: "80%" }}>
                                <QRCode value={QRvalue} />
                            </Col>

                        </Row>

                        <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "20px auto" }}>
                            <span style={{ fontWeight: "bold", fontSize: "17px" }}>Y oprime el boton de continuar cuando hayas realizado el pago
                            </span>
                        </div>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "0px auto", width: "80%" }}>
                                <button className="mp-big-button" style={{ border: "none", backgroundColor: "rgb(0 169 235)" }} onClick={getStatusNequi}>Continuar</button>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "20px auto", width: "80%" }}>
                                <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={newProcessNequi}>Generar nuevo proceso</button>
                            </Col>
                        </Row>


                    </>}

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "30px auto", width: "80%" }}>
                            <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={handleNequiModalClose}>Regresar</button>
                        </Col>
                    </Row>



                </Container>
            </Modal.Body>


        </Modal>



        <Modal show={showEfectyModal} onHide={handleEfectyModalClose} dialogClassName="modal-transfer">
            <Modal.Header closeButton>
                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "20px" }}>Pagar mediante Efecty
                    </span>
                </div>
            </Modal.Header>

            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", border: "solid", borderColor: "black", width: window.innerWidth > 600 ? "60%" : "80%" }}>
                            <br />
                            <b>Convenio:</b>{window.innerWidth < 600 && <br />} 113754<br /><br />
                            <b>Nombre convenio:</b>{window.innerWidth < 600 && <br />} Meddipay pago facturas<br /><br />
                            <b>Referencia:</b> {window.innerWidth < 600 && <br />} {selectedReference?.Reference}<br /><br />


                        </Col>
                    </Row>

                    {/* <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", width: "80%" }}>
                            <p>Una vez realizada la transferencia, por favor, enviar soporte, junto con referencia del crédito a cual esta realizando el pago al siguiente correo electrónico: </p>
                            <a target="_blank" rel="noreferrer" href="mailto:hola@meddipay.com">hola@meddipay.com</a>

                        </Col>
                    </Row> */}


                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "50px auto", width: "80%" }}>
                            <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={handleEfectyModalClose}>Regresar</button>
                        </Col>
                    </Row>



                </Container>
            </Modal.Body>


        </Modal>


        <Modal show={showAdvancePaymentModal} onHide={handleAdvancePaymentModalClose} dialogClassName="modal-transfer">
            <Modal.Header closeButton>
                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "20px" }}>¿Que desea hacer con el abono anticipado?
                    </span>
                </div>
            </Modal.Header>

            <Modal.Body>
                <Container>
                    <Row>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", width: "80%" }}>
                            Usted a ingresado el valor a pagar de  <b>{paymentType?.LabelAmount}</b> pero ahora necesitamos saber que debemos hacer con este abono anticipado.
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", border: "solid 1px", borderColor: "black", width: "80%", borderRadius: "10px", cursor: "pointer", backgroundColor: apOption === 1 ? "#eeffe7" : "unset" }} onClick={() => handleApOption(1)}>
                            <p style={{ fontWeight: "bold", marginTop: "15px" }}>Reservar para futuras cuotas.</p>
                            <p> El valor excedente se guardara en nuestro sistema y se aplicara a la siguiente cuota tan pronto se emita su facturación.
                                Nada cambiara en tu plan de pagos.</p>

                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", border: "solid 1px", borderColor: "black", width: "80%", borderRadius: "10px", cursor: "pointer", backgroundColor: apOption === 2 ? "#eeffe7" : "unset" }} onClick={() => handleApOption(2)}>
                            <p style={{ fontWeight: "bold", marginTop: "15px" }}>Abonar para reducir el plazo del Crédito.</p>
                            <p> El plazo de la finalización de tu crédito disminuirá siempre y cuando sea posible, y se conservará el mismo valor de cuota. Recuerde que esta opción no afecta a su Proxima facturación que será el dia <b>{selectedReference.NextBillingDate}</b>.</p>

                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", border: "solid 1px", borderColor: "black", width: "80%", borderRadius: "10px", cursor: "pointer", backgroundColor: apOption === 3 ? "#eeffe7" : "unset" }} onClick={() => handleApOption(3)}>
                            <p style={{ fontWeight: "bold", marginTop: "15px" }}>Abonar para reducir la cuota del Crédito</p>
                            <p>El valor de la cuota disminuirá, pero el plazo de tu plan de pagos seguirá siendo el mismo.  Recuerde que esta opción no afecta a su Proxima facturación que será el dia <b>{selectedReference.NextBillingDate}</b>.</p>

                        </Col>
                    </Row>



                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} style={{ textAlign: "center", margin: "20px auto" }}>
                            <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={handleAdvancePaymentModalClose}>Regresar</button>

                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} style={{ textAlign: "center", margin: "20px auto" }}>

                            <button className="mp-big-button bg-mp-primary" style={{ border: "none" }} onClick={advancePaymentRequest} >Continuar</button>
                        </Col>
                    </Row>



                </Container>
            </Modal.Body>


        </Modal>


        <Modal show={showOverPaymentModal} onHide={handleOverPaymentModalClose} dialogClassName="modal-transfer">
            <Modal.Header closeButton>
                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "20px" }}>¿Que deseas hacer con el abono anticipado?
                    </span>
                </div>
            </Modal.Header>

            <Modal.Body>
                <Container>
                    <Row>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", width: "80%" }}>
                            Por favor selecciona que deseas realizar con el pago anticipado de {FPayment !== 2 ? <b>$ {moneyLocale.format((paymentType?.LabelAmount ? paymentType?.LabelAmount.replaceAll("$", "").replaceAll(".", "") : 0) - (selectedMinimumPayment?.AmountInCents) / 100)}</b>
                                :
                                <b>$ {moneyLocale.format((paymentType?.LabelAmount ? paymentType?.LabelAmount.replaceAll("$", "").replaceAll(".", "") : 0))}</b>}
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", border: "solid 1px", borderColor: "black", width: "80%", borderRadius: "10px", cursor: "pointer", backgroundColor: opOption === 1 ? "#eeffe7" : "unset" }} onClick={() => handleOpOption(1)}>
                            <p style={{ fontWeight: "bold", marginTop: "15px" }}>Reservar para futuras cuotas.</p>
                            <p>  El valor del pago anticipado se guardara en nuestro sistema y se aplicara a la siguiente cuota tan pronto se emita su facturación. Nada cambiara en tu plan de pagos.
                                Afectara primero a tu facturación mas cercana que es el dia <b>{selectedReference?.NextBillingDate}</b></p>

                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", border: "solid 1px", borderColor: "black", width: "80%", borderRadius: "10px", cursor: "pointer", backgroundColor: opOption === 2 ? "#eeffe7" : "unset" }} onClick={() => handleOpOption(2)}>
                            <p style={{ fontWeight: "bold", marginTop: "15px" }}>Abonar para reducir el plazo del Crédito.</p>
                            <p> El plazo de finalización de tu crédito disminuirá siempre y cuando sea posible, y se conservará el mismo valor de cuota. Recuerda que esto no afecta a la facturación del dia <b>{selectedReference?.NextBillingDate}</b></p>

                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", border: "solid 1px", borderColor: "black", width: "80%", borderRadius: "10px", cursor: "pointer", backgroundColor: opOption === 3 ? "#eeffe7" : "unset" }} onClick={() => handleOpOption(3)}>
                            <p style={{ fontWeight: "bold", marginTop: "15px" }}>Abonar para reducir la cuota del Crédito</p>
                            <p>El valor de la cuota disminuirá, pero el plazo de tu plan de pagos seguirá siendo el mismo. Recuerda que esto no afecta a la facturación del dia <b>{selectedReference?.NextBillingDate}</b></p>

                        </Col>
                    </Row>



                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} style={{ textAlign: "center", margin: "20px auto" }}>
                            <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={handleOverPaymentModalClose}>Regresar</button>

                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} style={{ textAlign: "center", margin: "20px auto" }}>

                            <button className="mp-big-button bg-mp-primary" style={{ border: "none" }} onClick={overPaymentRequest}>Continuar</button>
                        </Col>
                    </Row>



                </Container>
            </Modal.Body>


        </Modal>

        <Modal show={showPendingPaymentModal} onHide={handlePendingPaymentModalClose} dialogClassName="modal-transfer">


            <Modal.Body>
                <Container style={{ minHeight: window.innerWidth < 600 ? "100vh" : "unset" }}>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ fontSize: "20px", textAlign: "center", margin: "20px auto", width: window.innerWidth < 600 ? "100%" : "80%", fontWeight: "bold" }}>

                            <img className="img-fluid" style={{ width: "100px" }}
                                src="assets/images/img/PPAlert.svg"
                                alt="paymentA" />
                            <br />
                            <br />
                            Cuentas con una cuota proxima a vencer
                        </Col>
                    </Row>



                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", width: window.innerWidth < 600 ? "100%" : "80%" }} >
                            <p style={{ marginTop: "15px", fontSize: "15px" }}>

                                Actualmente cuentas con una cuota proxima a vencer y por lo tanto pendiente por pagar por un valor de <b>{selectedMinimumPayment?.LabelAmount}</b> y fecha limite de pago el <b>{selectedMinimumPayment.LabelLimitDate}</b>.
                                Te recomendamos usar el valor introducido manualmente de <b>{paymentType?.LabelAmount}</b> para cubrir esta cuota.
                                <br /><br />
                                {Number(paymentType?.LabelAmount.replaceAll("$", "").replaceAll(".", "")) > (selectedMinimumPayment?.AmountInCents / 100) && <span>El sobrante de los <b>$ {moneyLocale.format((paymentType?.LabelAmount ? paymentType?.LabelAmount.replaceAll("$", "").replaceAll(".", "") : 0) - (selectedMinimumPayment?.AmountInCents) / 100)}</b> podrás usarlo para abonar a capital.</span>}
                                {Number(paymentType?.LabelAmount.replaceAll("$", "").replaceAll(".", "")) < (selectedMinimumPayment?.AmountInCents / 100) && <span>Recuerda que después de este pago, aun deberas pagar <b>$ {moneyLocale.format(((paymentType?.LabelAmount ? paymentType?.LabelAmount.replaceAll("$", "").replaceAll(".", "") : 0) - (selectedMinimumPayment?.AmountInCents) / 100) * -1)}</b> antes de la fecha limite de pago para seguir disponiendo de tu cupo y no incurrir en intereses moratorios.</span>}

                            </p>

                        </Col>
                    </Row>



                    <Row>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "20px auto" }}>

                            <button className="mp-big-button bg-mp-green" style={{ border: "none" }} onClick={() => handlePendingPayment(1)}>
                                Pagar cuota
                            </button>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "20px auto" }}>
                            <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={handlePendingPaymentModalClose}>Regresar</button>

                        </Col>
                    </Row>




                </Container>

                <Container>

                    <Row>


                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto" }}  >

                            <Accordion flush style={{ margin: "0 auto", textAlign: "center" }} className="accordion-movements">


                                <Card key="0" style={{ border: "none", boxShadow: "none", marginBottom: "10px", maxWidth: "100%", width: "100%" }}>
                                    <Card.Header style={{ padding: "0", border: "none" }}>
                                        <CustomToggleOtherOPS eventKey="0">
                                            <div style={{ padding: "15px", fontWeight: "bold", textAlign: "center", width: "100%", color: "#00a9eb", textDecoration: "underline" }}>Otras opciones</div>

                                        </CustomToggleOtherOPS>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body style={{ border: "none", padding: "5px", paddingTop: "20px", paddingBottom: "10px" }}>
                                            <Container>


                                                <button className="mp-big-button" style={{ borderRadius: "15px", border: "solid 2px red", backgroundColor: "white", color: "black" }} onClick={() => handlePendingPayment(2)}>Abonar todo el valor ({paymentType?.LabelAmount}) a capital</button>

                                            </Container>

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                            </Accordion>



                        </Col>
                    </Row>
                </Container>

            </Modal.Body>


        </Modal>

        <Modal show={showPlanModal} onHide={handlePlanModalClose} dialogClassName="modal-transfer">
            <Modal.Header closeButton>
                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "20px" }}>Plan de pagos Crédito<br /><span style={{ color: "#00a9eb" }}>#{selectedReference.Reference}</span>
                    </span>
                </div>
            </Modal.Header>

            <Modal.Body>
                {selectedReference && <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold" }}>
                            Pago mínimo
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", color: selectedMinimumPayment.Textcolor, fontWeight: selectedMinimumPayment.BoldText ? "bold" : "unset" }}>
                            {selectedMinimumPayment.LabelAmount}
                            <br />
                            <br />

                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold" }}>
                            Fecha límite de pago
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", color: selectedMinimumPayment.Textcolor, fontWeight: selectedMinimumPayment.BoldText ? "bold" : "unset" }}>
                            {selectedMinimumPayment.LabelLimitDate ?? "-"}
                            <br />
                            <br />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>

                            <Accordion flush style={{ margin: "0 auto" }} className="accordion-movements" >
                                {
                                    selectedReference && selectedReference.Installments && selectedReference.Installments.map(i => {

                                        return <Card key={i.NumInstalment} style={{ border: "none", boxShadow: "none", marginBottom: "10px", maxWidth: "100%", width: "100%" }}>
                                            <Card.Header style={{ padding: "0", border: "none" }}>
                                                <CustomToggle eventKey={i.NumInstalment}>
                                                    <div style={{ marginLeft: "10px", padding: "15px", fontWeight: "bold" }}>{i.LabelInstalment}</div>
                                                    <div style={{ position: "absolute", "right": "15px", padding: "10px" }}>
                                                        <div style={{ padding: "5px", color: i.ColorTextInstalmentStatus, backgroundColor: i.ColorFillInstalmentStatus, borderRadius: "5px", fontWeight: "500" }}>
                                                            {i.LabelInstalmentStatus}
                                                        </div>

                                                    </div>
                                                </CustomToggle>
                                            </Card.Header>

                                            <Accordion.Collapse eventKey={i.NumInstalment}>
                                                <Card.Body style={{ border: "none", padding: "5px", paddingTop: "20px", paddingBottom: "10px" }}>
                                                    <Container>
                                                        <Row>
                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", fontWeight: "bold", marginBottom: "0px", paddingRight: "0px" }}>Valor Cuota</Col>
                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", textAlign: "right", marginBottom: "0px", paddingLeft: "0px", fontWeight: "500" }}>{i.LabelTotalInstalment}<span style={{ color: "#03A9F4" }}>{i.LabelPaidInstalment}</span></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={7} sm={7} md={7} lg={7} xl={7} style={{ fontSize: "12px", fontWeight: "bold", paddingRight: "0px" }}>Fecha de Facturación</Col>
                                                            <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{ fontSize: "12px", textAlign: "right", paddingLeft: "0px", fontWeight: "500" }}>{i.LabelBillingDate}</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={7} sm={7} md={7} lg={7} xl={7} style={{ fontSize: "12px", fontWeight: "bold", paddingRight: "0px" }}>Fecha Limite de Pago</Col>
                                                            <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{ fontSize: "12px", textAlign: "right", paddingLeft: "0px", color: i.ColorText, fontWeight: "500" }}>{i.LabelLimitDate}</Col>
                                                        </Row>


                                                        <Row>
                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", fontWeight: "bold", paddingRight: "0px" }}>Pago Mínimo</Col>
                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", textAlign: "right", paddingLeft: "0px", color: i.ColorText, fontWeight: "500" }}>{i.LabelMinimumPayment}</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", fontWeight: "bold", paddingRight: "0px" }}>Interses moratorios</Col>
                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", textAlign: "right", paddingLeft: "0px", color: i.ColorText, fontWeight: "500" }}>{i.LabelPenalty}</Col>
                                                        </Row>
                                                    </Container>

                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    })

                                }
                            </Accordion>

                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "50px auto", width: "80%" }}>
                            <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={handlePlanModalClose}>Regresar</button>
                        </Col>
                    </Row>


                </Container>}
            </Modal.Body>


        </Modal>


    </>)
}


export default Payment;